<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="4">
        <v-text-field
          outlined
          dense
          filled
          readonly
          label="Cost Center"
          v-model="record.OcrCode"
        ></v-text-field>
      </v-col>

      <v-col cols="4">
        <v-text-field
          outlined
          dense
          label="Name"
          v-model="record.OcrName"
        ></v-text-field>
      </v-col>

      <v-col cols="4">
        <v-autocomplete
          outlined
          dense
          v-model="record.DimCode"
          :items="dimensions"
          item-text="DimName"
          item-value="id"
          label="Dimension"
        ></v-autocomplete>
      </v-col>
      <v-col cols="4">
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-text-field
              label="Valid From"
              readonly
              v-on="on"
              dense
              v-model="record.ValidFrom"
              outlined
            ></v-text-field>
          </template>
          <v-date-picker v-model="record.ValidFrom"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="4">
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-text-field
              dense
              label="Valid To"
              readonly
              v-on="on"
              outlined
              v-model="record.ValidTo"
            ></v-text-field>
          </template>
          <v-date-picker v-model="record.ValidTo"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="4">
        <v-text-field
          outlined
          dense
          filled
          readonly
          label="Total"
          v-model="record.OcrTotal"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row dense>
      <v-tabs background-color="accent" color="white">
        <v-tab>Centers</v-tab>
        <v-tab>Defaults</v-tab>
        <v-tab-item>
          <v-row>
            <v-col v-if="record.ocr1" cols="12">
              <v-data-table :headers="headers" :items="record.ocr1">
                <template v-slot:item.OcrCode="{ item }">
                  <v-btn
                    color="accent"
                    text
                    :to="`/cost-accounting/distribution-rules/${item.id}`"
                    >{{ item.OcrCode }}</v-btn
                  >
                </template>
                <template v-slot:item.OcrName="{ item }">
                  <v-btn
                    color="accent"
                    text
                    :to="`/cost-accounting/distribution-rules/${item.id}`"
                    >{{ item.OcrName }}</v-btn
                  >
                </template>

                <template v-slot:item.created_at="{ item }">{{
                  item.created_at | moment("Do MMM YYYY")
                }}</template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-tab-item>

        <v-tab-item>
          <!-- <v-row>
            <v-col cols="4">
              <v-autocomplete
                outlined
                dense
                v-model="record.SalePerson"
                :items="salesEmployees"
                item-text="SlpName"
                item-value="id"
                label="Sales Employee"
              ></v-autocomplete>
            </v-col>

            <v-col cols="4">
              <v-autocomplete
                outlined
                dense
                v-model="record.Warehouse"
                :items="globalWarehouses"
                item-text="WhsName"
                item-value="id"
                label="Warehouse"
              ></v-autocomplete>
            </v-col>

            <v-col cols="4">
              <v-autocomplete
                outlined
                dense
                v-model="record.DftItmsGrpCod"
                :items="itemGroups"
                item-text="ItmsGrpNam"
                item-value="id"
                label="Item Group"
              ></v-autocomplete>
            </v-col>

            <v-col cols="4">
              <v-autocomplete
                outlined
                dense
                v-model="record.Driver"
                :items="drivers"
                item-text="RlpName"
                item-value="id"
                label="Driver"
              ></v-autocomplete>
            </v-col>
          </v-row> -->
        </v-tab-item>
      </v-tabs>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-btn color="accent" @click="sendData" :loading="loader">
          <v-icon left>mdi-content-save</v-icon>Save
        </v-btn>
      </v-col>
    </v-row>
    <snackbar ref="snackbar"></snackbar>
  </v-container>
</template>


<script>
export default {
  props: {
    initial: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    record: {},
    drivers: [],
    ocr1: [],
    headers: [
      { text: "Code", value: "PrcCode" },
      { text: "Description", value: "oprc.PrcName" },
      { text: "Amount", value: "PrcAmount" },
    ],
    loader: false,
    dimensions: [],
  }),
  watch: {
    initial: {
      handler: "init",
      immediate: true,
    },
  },
  methods: {
    init(val) {
      this.record = { ...val };
      console.log(this.record);
    },
    setDate(date) {
      this.record.date = date;
    },
    sendData() {
      this.loader = true;
      this.$emit("data", this.record);
    },
    getDimensions() {
      const self = this;
      this.$store
        .dispatch("get", `/dimensions`)
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.dimensions = res.ResponseData;
          }
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getDimensions();
  },
};
</script>